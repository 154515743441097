<template>
 <span>
    <b-card-actions v-if="$permissionAbility(OUTREACH_INVOICE_CREATE, permissions)" title="Generate Invoice" action-collapse>
      <div>
        <!-- search input -->
        <div>
          <b-row>

            <b-col
                md="6"
                lg="4"
                xs="12"
            >
              <v-select
                  v-model="generateSiteListId"
                  :options="filteredSiteListOptions"
                  :reduce="(item) => item.id"
                  label="website"
                  placeholder="Select Website"
                  class="mb-1 custom-font"
              >
                <template #option="{ website, is_disabled }">
                  <span
                      :class="{'text-muted': is_disabled, 'disabled-option': is_disabled}"
                      :style="is_disabled ? { pointerEvents: 'none', cursor: 'not-allowed' } : {}"
                  >
                      {{ website }}
                  </span>
              </template>
            </v-select>

            </b-col>

            <b-col lg="3">
               <b-button
                   class="flex-shrink-0 ml-1"
                   v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                   variant="primary"
                   type="submit"
                   @click="onShowGenerate()"
                   :disabled="!generateSiteListId"
               >
                      Generate
                    </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card-actions>

    <b-card>
      <div>
        <!-- table -->
        <vue-good-table
            v-if="$permissionAbility(OUTREACH_INVOICE_SHOW, permissions)"
            styleClass="vgt-table table-custom-style striped"
            :line-numbers="false"
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :rows="rows"
            :columns="columns"
            :sort-options="{
            enabled: false,
            multipleColumns: true,
            initialSortBy: [{ field: 'created_at', type: 'desc' }],
          }"
            :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Back link Type  -->
            <template v-if="props?.column?.field === 'format_invoice_number'">
              <div v-if="props?.row?.invoice_number">
                <b-badge variant="light-primary">
                  {{ props?.row?.invoice_number }}
                </b-badge>
              </div>
              <div v-else>
                <b-badge variant="light-warning">NA</b-badge>
              </div>
            </template>

            <template v-if="props?.column?.field === 'format_website'">
              {{ formatWebsite(props?.row?.site_list?.data?.website) }}
            </template>

            <template v-if="props?.column?.field === 'seller_format'">
              <div v-if="props?.row?.site_list?.data?.seller?.data?.name">
                {{ props?.row?.site_list?.data?.seller?.data?.name }}
              </div>
              <div v-else>
                <b-badge variant="light-warning">NA</b-badge>
              </div>
            </template>

            <!-- Status  -->
            <template v-if="props?.column?.field === 'format_status'">

              <template v-if="!props?.row?.review">
                  <b-badge variant="light-secondary">
                    Pending
                  </b-badge>
              </template>

                <template v-else>
                  <div v-if="props?.row?.status === paymentPaidConstants">
                  <b-badge variant="light-success">
                    {{ props?.row?.status_text }}
                  </b-badge>
                  </div>
                  <div v-else>
                    <b-badge variant="light-danger">
                      {{ props?.row?.status_text }}
                    </b-badge>
                  </div>
                </template>
            </template>

            <template v-if="props?.column?.field === 'format_payment_method'">
                <b-badge variant="success">
                    {{ props?.row?.payment_method_text }}
                  </b-badge>
            </template>

            <span v-if="props.column.field === 'action'">
              <template v-if="props.row?.review">
                <span @click="onShowDetails(props.row?.id)">
                  <feather-icon
                      v-b-tooltip.hover
                      icon="EyeIcon"
                      class="mr-50 custom-icon cursor-pointer"
                      title="View"
                      size="16"
                  />
                </span>
              </template>

              <template v-if="$permissionAbility(OUTREACH_INVOICE_EDIT, permissions) && props.row?.status !== paymentPaidConstants">
                <span @click="onShow(props.row)">
                  <feather-icon
                      v-b-tooltip.hover
                      icon="Edit2Icon"
                      class="mr-50 custom-icon cursor-pointer"
                      title="Edit"
                      size="16"
                  />
                </span>
              </template>

              <template v-if="
                ($permissionAbility(OUTREACH_INVOICE_REVIEW, permissions) ||
                $permissionAbility(OUTREACH_INVOICE_PROXY_REVIEW, permissions)) &&
                 props.row?.status !== paymentPaidConstants &&
                 !props.row?.review
              ">
                <span @click="onReview(props.row)">
                  <feather-icon
                      v-b-tooltip.hover
                      icon="CheckIcon"
                      class="mr-50 custom-icon cursor-pointer"
                      title="Review"
                      size="16"
                  />
                </span>
              </template>

              <template v-if="$permissionAbility(OUTREACH_INVOICE_DELETE, permissions) && props.row?.status !== paymentPaidConstants">
                <span @click="onDelete(props.row)">
                  <feather-icon
                      v-b-tooltip.hover
                      icon="TrashIcon"
                      class="mr-50 custom-icon cursor-pointer"
                      title="Delete"
                      size="16"
                  />
                </span>
              </template>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                    v-model="pageLength"
                    :options="['10', '25', '50', '100', '500']"
                    class="mx-1"
                    @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>

    <b-modal
        id="modal-invoice-generate-form"
        centered
        :title="modelType === 'reviewModal' ? 'Review Invoice' : modelType === 'editModel' ? 'Edit Invoice' : 'Generate Invoice'"
        hide-footer
        @hidden="hiddenGenerateModal"
        size="lg"
        no-close-on-backdrop
    >
      <validation-observer ref="generateInvoiceForm">
        <b-form v-on:submit.prevent="generateInvoiceForm">
          <b-row>

             <b-col
                 md="6"
                 lg="6"
                 xs="12"
             >
               <b-form-group
                   label="Site List"
                   label-for="site_list"
                   class="required-label"
               >
                  <v-select
                      v-model="generateSiteListId"
                      :options="filteredSiteListOptions"
                      :reduce="(item) => item.id"
                      label="website"
                      placeholder="Select Website"
                      class="mb-1 custom-font"
                      disabled
                  />
               </b-form-group>
          </b-col>

            <b-col md="6" lg="6" xs="12">
              <!-- payment_method -->
              <b-form-group
                  label="Payment Method"
                  label-for="payment_method"
                  class="required-label"
              >
                <validation-provider
                    #default="{ errors }"
                    name="payment method"
                    vid="payment_method"
                    rules="required"
                >
                  <v-select
                      id="payment_method"
                      v-model="selectPaymentId"
                      :options="filteredPaymentOptions"
                      :reduce="(option) => option.id"
                      label="name"
                      placeholder="Choose Here"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" lg="6" xs="12">
              <!-- payment_date-->
              <b-form-group label="Payment Date" label-for="payment_date">
                <validation-provider
                    #default="{ errors }"
                    name="payment date"
                    vid="payment_date"
                    rules="required"
                >
                  <b-form-datepicker
                      id="payment_date"
                      v-model="paymentDateInput"
                      class="form-control custom-font"
                      :state="errors.length > 0 ? false : null"
                      locale="en-US"
                      today-button
                      close-button
                      reset-button
                      placeholder="Payment Date"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" lg="6" xs="12">
              <!-- approver_id -->
              <b-form-group
                  label="Approver"
                  label-for="approver_id"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Approver"
                    vid="approver_id"
                    rules="required"
                >
                  <v-select
                      id="approver_id"
                      v-model="selectApproverId"
                      :options="filteredApproverOptions"
                      :reduce="(option) => option.id"
                      label="name"
                      placeholder="Choose Here"
                  >
                  <template #option="data">
                    <UserSelect :user="data" />
                  </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>


            <b-col cols="12" lg="12" xs="12">
              <template v-if="unpaidBackLink.length > 0">
              <b-table responsive :items="unpaidBackLink" :fields="backLinkFields" class="mb-0">

                <template #cell(backlink)="data">
                   <div>
                    <!-- Backlink -->
                     <b>Backlink:</b>
                        <a
                            :href="data.item?.site_list?.website + '/' +data.item.backlink_url"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                           {{ formatWebsite(data.item.backlink_url) }}
                        </a>
                      </div>
                    <div>
                <!-- Targeted URL -->
                   <b>URL:</b>
                    <a
                        :href="data.item.targeted_url"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      {{ formatWebsite(data.item.targeted_url) }}
                    </a>

                      <div><b>Project</b>: {{ data.item.project?.data?.title }}</div>
                    </div>
                </template>

                <template #cell(project)="data">
                  <b-badge variant="light-primary">
                    {{ data.item.project?.data?.title }}
                  </b-badge>
                </template>

                <template #cell(type)="data">
                  <b-badge variant="light-primary">
                    {{ data.item.backlinks_type_text }}
                  </b-badge>
                </template>

               <template #cell(status)="data">

                 <template v-if="modelType === 'reviewModal' && $permissionAbility(BACK_LINK_REVIEW, permissions)">
                   <v-select
                       v-model="data.item.status"
                       :options="outreachStatusConstants"
                       :reduce="(item) => item.value"
                       label="name"
                       placeholder="Select Status"
                       class="mb-1 custom-font"
                       @input="backlinkStatusUpdate(data.item.id, data.item.status)"
                   />
                 </template>
                 <template v-else>
                   <b-badge :variant="getStatusVariant(data.item.status)">
                    {{ data.item.status_text }}
                  </b-badge>
                 </template>

              </template>


                <template #cell(price)="data">
                  {{ data.item.currency?.data?.currency }} {{ data.item.cost_price }}
                </template>

                <template #cell(action)="data">
                  <b-button v-if="modelType !== 'reviewModal'" variant="danger" size="sm" @click="unselectBacklink(data.index)">X</b-button>
                  <template v-else>
                    <b-badge variant="light-danger">
                      N/A
                    </b-badge>
                  </template>
                </template>

              </b-table>
            </template>
              <template v-else>
                   <h6 class="text-danger">No Unpaid Backlink</h6>
              </template>

              <div class="d-flex justify-content-end mr-5 mb-1">
                <div>
                  <h4>Total Amount: {{ unpaidBackLink[0]?.currency?.data?.currency }} {{ total_amount }}</h4>

                  <v-select
                      v-model="fee_type"
                      :options="discountTypeOptions"
                      :reduce="(item) => item.value"
                      label="name"
                      placeholder="Fee Type"
                      class="mb-1 custom-font"
                      @input="feeTypeChange"
                  />

                  <b-form-group v-if="fee_type && fee_type === 'percentage'" label="" label-for="Fee">
                    <validation-provider
                        #default="{ errors }"
                        name="fee value"
                        vid="fee_value"
                        :rules="fee_type === 'percentage' ? 'required|min_value:0|max_value:100' : 'min_value:0|max_value:100'"
                    >
                      <b-form-input
                          id="fee_value"
                          type="number"
                          v-model="fee_value"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Fee Value"
                          @input="calculateGrandTotal"
                          @change="calculateGrandTotal"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                  <b-form-group v-if="fee_type" label="" label-for="Fee">
                    <validation-provider
                        #default="{ errors }"
                        name="fee amount"
                        vid="fee_amount"
                        :rules="fee_type === 'flat' ? 'required|min_value:0' : 'min_value:0'"
                    >

                      <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="spamScoreAddon">{{ unpaidBackLink[0]?.currency?.data?.currency }}</span>
                      </div>
                        <b-form-input
                            id="discount_amount"
                            type="number"
                            v-model="fee_amount"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Fee Amount"
                            @input="calculateGrandTotal"
                            :disabled="fee_type === 'percentage'"
                        />
                      </div>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                  <!--                  Fee Calculation Ends here-->

                  <!--                  Discount calculation start here-->
                  <v-select
                      v-model="discount_type"
                      :options="discountTypeOptions"
                      :reduce="(item) => item.value"
                      label="name"
                      placeholder="Discount Type"
                      class="mb-1 custom-font"
                      @input="discountTypeChange"
                  />

                  <b-form-group v-if="discount_type && discount_type === 'percentage'" label="" label-for="Discount">
                    <validation-provider
                        #default="{ errors }"
                        name="discount value"
                        vid="discount_value"
                        :rules="discount_type === 'percentage' ? 'required|min_value:0|max_value:100' : 'min_value:0|max_value:100'"
                    >
                      <b-form-input
                          id="discount_value"
                          type="number"
                          v-model="discount_value"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Discount Value"
                          @input="calculateGrandTotal"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                  <b-form-group v-if="discount_type" label="" label-for="Discount">
                    <validation-provider
                        #default="{ errors }"
                        name="discount amount"
                        vid="discount_amount"
                        :rules="(discount_type === 'flat' ? 'required|' : '') + 'min_value:0|max_value:' + (parseFloat(total_amount) + (fee_amount ? parseFloat(fee_amount) : 0))"
                    >


                      <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="spamScoreAddon">{{ unpaidBackLink[0]?.currency?.data?.currency }}</span>
                      </div>
                        <b-form-input
                            id="discount_amount"
                            type="number"
                            v-model="discount_amount"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Discount Amount"
                            @input="calculateGrandTotal"
                            :disabled="discount_type === 'percentage'"
                        />
                      </div>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                  <h4>Grand Total: {{ unpaidBackLink[0]?.currency?.data?.currency }} {{ grand_total }}</h4>

                </div>
              </div>
            </b-col>

            <template v-if="unSelectedUnpaidBackLink.length > 0">
                <h4 class="m-2">Unselected</h4>
                <b-col cols="12" lg="12" xs="12">
              <template v-if="unSelectedUnpaidBackLink.length > 0">
              <b-table responsive :items="unSelectedUnpaidBackLink" :fields="backLinkFields" class="mb-0">

                <template #cell(backlink)="data">
                   <div>
                    <!-- Backlink -->
                     <b>Backlink:</b>
                        <a
                            :href="data.item?.site_list?.website + '/' +data.item.backlink_url"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                           {{ formatWebsite(data.item.backlink_url) }}
                        </a>
                      </div>
                    <div>
                <!-- Targeted URL -->
                   <b>URL:</b>
                    <a
                        :href="data.item.targeted_url"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      {{ formatWebsite(data.item.targeted_url) }}
                    </a>

                      <div><b>Project</b>: {{ data.item.project?.data?.title }}</div>
                    </div>
                </template>

                <template #cell(project)="data">
                  <b-badge variant="light-primary">
                    {{ data.item.project?.data?.title }}
                  </b-badge>
                </template>

                <template #cell(type)="data">
                  <b-badge variant="light-primary">
                    {{ data.item.backlinks_type_text }}
                  </b-badge>
                </template>

               <template #cell(status)="data">
                  <b-badge :variant="getStatusVariant(data.item.status)">
                    {{ data.item.status_text }}
                  </b-badge>
                </template>


                <template #cell(price)="data">
                  <b-badge variant="light-success">{{ data.item.currency?.data?.currency }} {{ data.item.cost_price }}</b-badge>
                </template>

                <template #cell(action)="data">
                  <b-button variant="success" size="sm" @click="selectUnselectedBacklink(data.index)">
                    <feather-icon
                        v-b-tooltip.hover
                        icon="CheckIcon"
                        title="Select"
                        size="16"
                    /></b-button>
                </template>

              </b-table>
            </template>
            <template v-else>
                 <h6 class="text-danger">No Unselected Unpaid Backlink</h6>
            </template>
            </b-col>
            </template>

            <b-col md="12" lg="12" xs="12" class="mb-10">
              <b-form-group label="Payment Details" label-for="payment_details">
                <ValidationProvider
                    #default="{ errors }"
                    name="Payment Details"
                    vid="payment_details"
                >
                  <quill-editor
                      v-model="paymentDetailsInput"
                      :options="editorOption"
                      :style="{
                      width: '98% !important'
                    }"
                      id="payment_details"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Payment Details"
                  >
                    <div id="toolbar" slot="toolbar">
                      <!-- Add a bold button -->
                      <span class="ql-formats">
                        <button class="ql-bold">
                          Bold
                        </button>
                        <button class="ql-italic">
                          Italic
                        </button>
                        <button class="ql-underline">
                          Underline
                        </button>
                        <button class="ql-strike">
                          Strike
                        </button>
                        <button
                            class="ql-blockquote"
                        ></button>
                        <button
                            class="ql-list"
                            value="ordered"
                        ></button>
                        <button
                            class="ql-list"
                            value="bullet"
                        ></button>
                        <button
                            class="ql-script"
                            value="sub"
                        ></button>
                        <button
                            class="ql-script"
                            value="super"
                        ></button>
                        <button
                            class="ql-indent"
                            value="-1"
                        ></button>
                        <button
                            class="ql-indent"
                            value="+1"
                        ></button>
                        <button
                            class="ql-direction"
                            value="rtl"
                        ></button>
                        <button
                            class="ql-align"
                            value=""
                        ></button>
                        <button
                            class="ql-align"
                            value="center"
                        ></button>
                        <button
                            class="ql-align"
                            value="right"
                        ></button>
                        <button
                            class="ql-align"
                            value="justify"
                        ></button>
                        <select class="ql-color"></select>
                        <select
                            class="ql-background"
                        ></select>
                        <select class="ql-size">
                          <option value="small" />

                          <option selected />
                          <option value="large" />
                          <option value="huge" />
                        </select>

                        <select class="ql-font"></select>
                        <select class="ql-header">
                          <option value="1">
                            Heading 1
                          </option>
                          <option value="2">
                            Heading 2
                          </option>
                          <option value="3">
                            Heading 3
                          </option>
                          <option value="4">
                            Heading 4
                          </option>
                          <option value="5">
                            Heading 5
                          </option>
                          <option value="6">
                            Heading 6
                          </option>
                          <option selected>Normal</option>
                        </select>

                        <button class="ql-link"></button>
                      </span>
                    </div>
                  </quill-editor>

                  <small class="text-danger">{{
                      errors[0]
                    }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col md="12" lg="12" xs="12">
              <!-- remarks -->
              <b-form-group label="Remarks" label-for="remarks">
                <validation-provider
                    #default="{ errors }"
                    name="Remarks"
                    vid="remarks"
                >

                  <b-form-textarea
                      id="remarks"
                      v-model="remarksInput"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Remarks"
                      rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- loading button -->
          <template v-if="isSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>

            <template v-if="modelType === 'reviewModal'">
              <b-button
                  :disabled="!payment_active"
                  type="submit"
                  class="float-right"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
              >Proceed to Payment</b-button>
            </template>
            <template v-else>

              <b-button
                  :disabled="unpaidBackLink.length <= 0"
                  type="submit"
                  class="float-right"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
              >{{ modelType === 'editModel' ? 'Update' : 'Generate' }}</b-button>


            </template>

          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
 </span>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BRow,
  BCol,
  VBTooltip,
  BFormDatepicker,
  BFormTextarea, BTable,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { quillEditor } from "vue-quill-editor";
import { required, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import UserAssignPolicyForm from "@/views/admin/payroll-management/UserAssignPolicyForm.vue";
import UserSelect from '@/layouts/components/UserSelect.vue'
import {
  OUTREACH_INVOICE_ACCESS,
  OUTREACH_INVOICE_CREATE,
  OUTREACH_INVOICE_EDIT,
  OUTREACH_INVOICE_DELETE,
  OUTREACH_INVOICE_SHOW,
  OUTREACH_INVOICE_REVIEW,
  OUTREACH_INVOICE_PROXY_REVIEW,
  BACK_LINK_REVIEW,
  OUTREACH_INVOICE_PAYMENT
} from "@/helpers/permissionsConstant";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { paymentPaidConstants, paymentUnpaidConstants, paymentDueConstants, outreachInvoiceStatusConstants } from "@/helpers/constant/outreachPaymentStatusConstant";
import { approvedStatusConstants, pendingStatusConstants, rejectedStatusConstants, softRejectedStatusConstants, outreachStatusConstants } from "@/helpers/constant/outreachStatusConstant";

export default {
  name: "OutreachInvoiceView",
  components: {
    BTable,
    BCardActions,
    UserAssignPolicyForm,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BRow,
    BCol,
    VBTooltip,
    BFormDatepicker,
    BFormTextarea,
    UserSelect,
    quillEditor,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  watch: {
    unpaidBackLink(newValue) {
      this.total_amount = 0
      this.total_amount = this.unpaidBackLink.reduce((sum, item) => {
        return sum + (item.cost_price || 0)
      }, 0);

      this.payment_active = false
      this.payment_active = this.unpaidBackLink.every(item => item.status === this.approvedStatusConstants);
    },
    total_amount() {
      this.calculateGrandTotal();
    },
  },
  data() {
    return {

      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },

      // Permissions
      OUTREACH_INVOICE_ACCESS,

      OUTREACH_INVOICE_CREATE,
      OUTREACH_INVOICE_EDIT,
      OUTREACH_INVOICE_DELETE,
      OUTREACH_INVOICE_SHOW,
      OUTREACH_INVOICE_REVIEW,
      OUTREACH_INVOICE_PROXY_REVIEW,
      OUTREACH_INVOICE_PAYMENT,
      BACK_LINK_REVIEW,

      outreachInvoiceStatusConstants,
      paymentPaidConstants,
      paymentUnpaidConstants,
      paymentDueConstants,

      outreachStatusConstants,

      approvedStatusConstants,
      pendingStatusConstants,
      rejectedStatusConstants,
      softRejectedStatusConstants,

      payment_active: true,
      filterSelectWebsiteId: "",
      generateSiteListId: "",
      filteredSiteListOptions: [],
      unpaidBackLink: [],
      total_amount: 0,
      grand_total: 0,
      fee_amount: '',
      discount_amount: '',
      discount_value: '',
      discount_type: '',

      fee_value: '',
      fee_type: '',

      unSelectedUnpaidBackLink: [],
      backLinkFields: ["backlink", "type", "status", "price", "action"],

      discountTypeOptions: [
        { value: 'percentage', name: "Percentage" },
        { value: 'flat', name: "Flat" }
      ],

      // invoice
      invoiceNumberInput: "",
      invoiceDateInput: "",
      selectSellerId: "",
      selectPaymentId: "",
      filteredPaymentOptions: [
        { id: 'paypal', name: "PayPal" },
        { id: 'cash', name: "Cash" },
        { id: 'mobile_banking', name: "Mobile banking" },
        { id: 'crypto', name: "Crypto" },
        { id: 'payoneer', name: "Payoneer" },
        { id: 'bank', name: "Bank Transfer" }
      ],
      paymentDateInput: "",
      selectInvoiceStatusId: "",
      filteredInvoiceStatusOptions: [
        { id: 0, name: "Pending" },
        { id: 1, name: "Awaiting Finance Approval" },
        { id: 2, name: "Paid" },
        { id: 3, name: "Rejected" },
      ],
      paymentDetailsInput: "",
      remarksInput: "",
      backLinkId: "",

      modelType: "",
      name: "",

      outreachInvoiceId: "",
      nicheName: "",

      selectApproverId: "",
        filteredApproverOptions: [],

      //table
      pageLength: 10,
      columns: [
        {
          label: "Invoice Number",
          field: "format_invoice_number",
          sortable: false,
        },
        {
          label: "Website",
          field: "format_website",
          sortable: false,
        },
        {
          label: "Seller",
          field: "seller_format",
          sortable: false,
        },
        {
          label: "Payment Method",
          field: "format_payment_method",
          sortable: false,
        },
        {
          label: "Status",
          field: "format_status",
          sortable: false,
        },
        {
          label: "Payment Date",
          field: "payment_date",
          formatFn: this.formatDate,
          sortable: false,
        },
        {
          label: "Generated Date",
          field: "created_at",
          formatFn: this.formatDate,
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },

  async created() {
    try {
      await this.getFilteredSiteListOptions();
      this.loadItems();
      await this.getAllApprovers();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {

    discountTypeChange()
    {
      this.discount_amount = ''
      this.discount_value = ''
      this.calculateGrandTotal()
    },

    feeTypeChange()
    {
      this.fee_amount = ''
      this.fee_value = ''
      this.calculateGrandTotal()
    },

    calculateGrandTotal() {

      let grandTotal = this.total_amount
      // Apply fee

      if(!this.fee_value)
      {
        this.fee_value = 0
      }

      if(!this.discount_value)
      {
        this.discount_value = 0
      }

      if (this.fee_type === 'percentage') {
        // If it's a percentage, calculate the discount on the total amount
        this.fee_amount = (parseFloat(this.fee_value) / 100) * grandTotal;
        grandTotal += this.fee_amount;
      } else if (this.fee_amount) {
        grandTotal += parseFloat(this.fee_amount);
      }

      // Apply discount
      if (this.discount_type === 'percentage') {
        // If it's a percentage, calculate the discount on the total amount
        this.discount_amount = (parseFloat(this.discount_value) / 100) * grandTotal;
        grandTotal -= this.discount_amount;
      } else if (this.discount_amount) {
        // If it's a fixed discount amount, subtract that
        grandTotal -= parseFloat(this.discount_amount);
      }

      // Store the final calculated grand total
      this.grand_total = grandTotal;
    },

    async backlinkStatusUpdate(id, status)
    {
      await this.$api.put(`/api/backlinks/${id}/status`, { status: status });
      const invoice = await this.$api.get(`/api/outreach-invoices/${this.outreachInvoiceId}?include=backlinks`);
      this.unpaidBackLink = []
      this.unpaidBackLink = invoice?.data?.data?.backlinks?.data

      await this.showGenerateModal()
    },

    unselectBacklink(index) {
      // Remove the item at the given index
      const item = this.unpaidBackLink.splice(index, 1)[0];
      this.unSelectedUnpaidBackLink.push(item)
    },

    selectUnselectedBacklink(index) {
      // Remove the item at the given index
      const item = this.unSelectedUnpaidBackLink.splice(index, 1)[0];
      this.unpaidBackLink.push(item);

    },

    getStatusVariant(status) {
      // Map status to badge variants
      switch (status) {
        case this.approvedStatusConstants:
          return "light-success";
        case this.pendingStatusConstants:
          return "light-warning";
        case this.rejectedStatusConstants:
          return "light-danger";
        default:
          return "light-secondary"; // Default variant
      }
    },

    formatWebsite(website) {
      const maxLength = 20;
      if (website.length > maxLength) {
        return website.slice(0, maxLength) + "...";
      }
      return website;
    },

    showModal() {
      this.$bvModal.show("modal-create-form");
    },

    hiddenModal() {
      this.$bvModal.hide("modal-create-form");
      this.resetModal();
    },

    resetModal() {
      this.modelType = "";
      this.outreachInvoiceId = "";

      this.nicheName = "";
      this.invoiceNumberInput = "";
      this.invoiceDateInput = "";
      this.selectSellerId = "";
      this.selectPaymentId = "";
      this.paymentDateInput = "";
      this.selectInvoiceStatusId = "";
      this.paymentDetailsInput = "";
      this.remarksInput = "";
      this.backLinkId = "";
      this.selectApproverId = "";
    },

    onShow(value) {
      this.modelType = "editModel";
      this.outreachInvoiceId = value.id

      this.unSelectedUnpaidBackLink = []
      this.unpaidBackLink = []

      this.unpaidBackLink = value.backlinks.data

      this.generateSiteListId = value.site_list_id;
      this.selectPaymentId = value.payment_method;
      this.paymentDateInput = value.payment_date;
      this.selectApproverId = value.approver_id;
      this.paymentDetailsInput = value.payment_details;
      this.remarksInput = value.remarks;

      this.total_amount = value.total_amount
      this.fee_amount = value.fee_amount
      this.discount_amount = value.discount_amount
      this.discount_value = value.discount_value
      this.discount_type = value.discount_type

      this.fee_value = value.fee_value
      this.fee_type = value.fee_type

      this.onShowGenerate();
    },

    onReview(value) {
      this.modelType = "reviewModal";
      this.outreachInvoiceId = value.id

      this.unSelectedUnpaidBackLink = []

      this.unpaidBackLink = value.backlinks.data

      this.generateSiteListId = value.site_list_id;
      this.selectPaymentId = value.payment_method;
      this.paymentDateInput = value.payment_date;
      this.selectApproverId = value.approver_id;
      this.paymentDetailsInput = value.payment_details;
      this.remarksInput = value.remarks;

      this.total_amount = value.total_amount
      this.fee_amount = value.fee_amount
      this.discount_amount = value.discount_amount
      this.discount_value = value.discount_value
      this.discount_type = value.discount_type

      this.fee_value = value.fee_value
      this.fee_type = value.fee_type

      this.showGenerateModal();
    },

    onShowDetails(id) {
      this.$router.push({
        name: "outreach-invoice-details",
        params: { id },
      });
    },

    async onShowGenerate() {

      const response = await this.$api.get("api/backlinks/approved-unpaid/" + this.generateSiteListId + "?include=project,currency");

      if(this.modelType !== 'editModel') {

        this.unSelectedUnpaidBackLink = []
        this.unpaidBackLink = response.data.data

      }else{

        const invoice = await this.$api.get(`/api/outreach-invoices/${this.outreachInvoiceId}?include=backlinks`);
        this.unpaidBackLink = []
        this.unpaidBackLink = invoice?.data?.data?.backlinks?.data

        this.unSelectedUnpaidBackLink = response.data.data
      }

      this.showGenerateModal();
    },

    showGenerateModal() {
      this.$bvModal.show("modal-invoice-generate-form");
    },

    hiddenGenerateModal() {
      this.$bvModal.hide("modal-invoice-generate-form");
      this.resetGenerateModal();
    },

    resetGenerateModal() {
      this.modelType = "";
      this.generateSiteListId = ""
      this.selectPaymentId = ""
      this.paymentDateInput = ""
      this.selectApproverId = ""
      this.remarksInput = ""
      this.paymentDetailsInput = ""
      this.unpaidBackLink = []
      this.total_amount = 0
      this.grand_total = 0
      this.fee_amount = ''
      this.discount_amount = ''
      this.discount_value = ''
      this.discount_type = ''

      this.fee_value = ''
      this.fee_type = ''
    },

    async getFilteredSiteListOptions() {
      try {
        const response = await this.$api.get("/api/outreach/site-list/all/approved");

        this.filteredSiteListOptions = (response.data.data || []).map((item) => {
          let website = item.website;
          let is_disabled = item.is_disabled;
          return {
            id: item.id,
            approveforNiches: item.approveforNiches,
            website,
            is_disabled,
          };
        });

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },


    formatDateTime(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }else{
        return 'N/A'
      }
    },

    async getAllApprovers() {
      try {
        const response = await this.$api.get("api/outreach-invoices/reviewers");

        this.filteredApproverOptions = (response?.data?.data || []).map(
          item => ({
            avatar: item?.avatar,
            name: item?.name,
            email: item?.email,
            mobile: item?.mobile,
            employee_number: item?.employee_number,
            id: item?.id,
            departmentId: item?.department_id,
          }),
        );

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async onDelete(row) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete "+row?.invoice_number+"?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/outreach-invoices/${row?.id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Outreach Invoice Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    async getOutreachNicheItems(params) {
      return await this.$api.get("api/outreach-invoices?include=site_list.seller,backlinks, backlinks.currency", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    async loadItems() {
      try {
        const outreachNiche = await this.getOutreachNicheItems({
          show: this.serverParams.perPage,
          page: this.serverParams.page,
          sort: this.serverParams.sort,
          q: this.searchTerm,
        });

        const data = outreachNiche?.data?.data;
        const meta = outreachNiche?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.createFormValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.createFormValidation.reset();
            if (this.modelType == "editModel") {
              this.isSubmitLoading = true;
              await this.$api.put(
                `/api/outreach-invoices/${this.outreachInvoiceId}`,
                {
                  invoice_number: this.invoiceNumberInput,
                  invoice_date: this.invoiceDateInput,
                  seller_id: this.selectSellerId,
                  payment_method: this.selectPaymentId,
                  payment_date: this.paymentDateInput,
                  status: this.selectInvoiceStatusId,
                  payment_details: this.paymentDetailsInput,
                  remarks: this.remarksInput,
                  backlink_id: this.backLinkId,
                  approver_id: this.selectApproverId,
                }
              );
              this.isSubmitLoading = false;
              this.loadItems();

              this.hiddenModal();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Outreach Seller Successfully Updated",
                },
              });
            }
          } catch (error) {
            this.isSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.createFormValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
    generateInvoiceForm: async function () {
      this.$refs.generateInvoiceForm.validate().then(async (success) => {
        if (success) {
          try {

            if (this.unpaidBackLink.length <= 0) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: "No backlinks selected",
                },
              });
              return;
            }

            this.$refs.generateInvoiceForm.reset();
            this.isSubmitLoading = true;
            const formattedBacklinks = this.unpaidBackLink.map((backlink) => backlink.id)

            const payload = {
              payment_method: this.selectPaymentId,
              site_list_id: this.generateSiteListId,
              payment_date: this.paymentDateInput,
              approver_id: this.selectApproverId,
              backlinks: formattedBacklinks,
              payment_details: this.paymentDetailsInput,
              remarks: this.remarksInput,
              total_amount: this.total_amount,
              fee: this.fee_amount,
              discount_amount: this.discount_amount,
              discount_value: this.discount_value,
              discount_type: this.discount_type,

              fee_value: this.fee_value,
              fee_type: this.fee_type,
            };

            if (this.modelType === 'editModel') {
              // For editing, send a PUT request
              await this.$api.put(`/api/outreach-invoices/${this.outreachInvoiceId}`, payload);
            } else if (this.modelType === 'reviewModal') {
              // For creating, send a PUT request
              await this.$api.put(`/api/outreach-invoices/${this.outreachInvoiceId}/reviewed`, payload);
            } else {
              // For creating, send a POST request
              await this.$api.post(`/api/outreach-invoices`, payload);
            }

            this.isSubmitLoading = false;
            this.loadItems();

            this.hiddenGenerateModal();
          } catch (error) {
            this.isSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.generateInvoiceForm.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/quill.scss";

.required-label label::after {
  content: " *";
  color: red;
}
</style>

